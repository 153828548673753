(function() {
    'use strict';

    angular.module('EntrakV5').directive('translate', ['Service', '$compile', function(Service, $compile) {
	    return {
	    	restrict: "A",
	    	scope: {
	    		translate: "@",
	    		transParams: "=",
	    	},
			link: function(scope, elem, attr){
				if (scope.translate){
					scope.$watch('translate', function(n){
						elem.html(Service.translate(n, scope.transParams));
					});
				}
				if (scope.transParams){
					var key = elem.text();
					scope.$watch('transParams', function(n){
						elem.html(Service.translate(scope.translate ? scope.translate : key, n));
					});
				}
				if (!scope.translate && !scope.transParams)
					elem.html(Service.translate(elem.text(), scope.transParams));
			}
	    }
	}]).directive('etScrolled', [function() {
		return {
			restrict: "A",
			scope: {
				etScrolled: "&",
			},
			link: function(scope, element, attrs) {
				scope.scrolled = function(){
					scope.etScrolled();
				}
				element.on('scroll', scope.scrolled);
				scope.$on('$destroy', function() {
					element.off('scroll', scope.scrolled);
				});
			}
		}
	}]).directive("etTable", ['KEY', function(KEY){
		return {
			restrict: "E",
			link: function(scope, element, attrs){
				element.addClass("etTable");
				$(".etTable .etTableHead").css({'padding-right': KEY.scrollBarWidth + 'px'});
			}
		}
	}]);
    
})();
