import {ApolloClient} from 'apollo-client';
import {setContext} from "apollo-link-context";
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import gql from "graphql-tag";
import {v4 as uuidv4} from 'uuid';

(function() {
    'use strict';

    angular.module('EntrakV5').service('Api', ['$http', 'Service', 'URL', 'MS_ENDPOINT', 'CLIENT_ID', api]);

    function api($http, Service, URL, MS_ENDPOINT, CLIENT_ID) {
        console.log('api service');

        var thisDomain = window.location.origin + '/';

    // graphQL
        var client = null;
        function initApolloClient(signInMethod, idToken) {
            var authLink = setContext(function(_, opt) {
              var tmp = {
                headers: {
                  ...opt.headers,
                  "x-request-id": uuidv4(),
                },
              }
              if (idToken)
                tmp.headers.authorization = "Bearer " + idToken;
              tmp.headers["authorization-method"] = signInMethod;

              return tmp;
            });

            client = new ApolloClient({
                link: authLink.concat(createUploadLink({ uri: `${URL}api/gql`, credentials: 'include' })),
                cache: new InMemoryCache()
            });
        }

        function toApolloQuery(queryStr, ...fragmentStr){
            return client.query({
                fetchPolicy: 'no-cache',
                query: gql('query {' + queryStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutation(mutationStr, ...fragmentStr){
            return client.mutate({
                mutation: gql('mutation {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutationWithFile(mutationStr, file1, ...fragmentStr){
            return client.mutate({
                variables: {
                    file1: file1
                },
                mutation: gql('mutation($file1: Upload!) {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }
        function toApolloMutationWith2File(mutationStr, file1, file2, ...fragmentStr){
            return client.mutate({
                variables: {
                    file1: file1,
                    file2: file2,
                },
                mutation: gql('mutation($file1: Upload!, $file2: Upload!) {' + mutationStr + '}' + fragmentStr.join(""))
            });
        }

        //QueryParam class, new it
        function QueryParam(){
            this.value = "";
        }
        QueryParam.prototype.toSafeStr = function(val){
            if (typeof val != "string")
                val += "";
            var safeStr = val.replace(/\\|"/g, '');
            if (safeStr !== val)
                console.warn("unsafe str removed", val);
            return safeStr;
        }
        QueryParam.prototype.val = function(paramName, val){
            if (paramName){
                this.value += paramName + ':' + val + ' ';
            } else {
                this.value += val + ' ';
            }
            return this;
        }
        QueryParam.prototype.str = function(paramName, val){
            if (val == null){
                val = "";
            } else {
                val = this.toSafeStr(val);
            }
            if (paramName){
                this.value += paramName + ':"' + val + '" ';
            } else {
                this.value += '"' + val + '" ';
            }
            return this;
        }
        QueryParam.prototype.enum = function(paramName, val){
            val = this.toSafeStr(val);
            return this.val(paramName, val);
        }
        QueryParam.prototype.obj = function(paramName, val, objectTypes){    //objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], arr:[{fieldName, fieldType, objectTypes}, obj:[{fieldName, objectTypes}]}
            if (paramName){
                paramName = paramName + ":";
            } else {
                paramName = "";
            }

            if (!val){
                if (val !== undefined)
                    this.value += paramName + 'null ';
                return this;
            }

            this.value += paramName + '{ ';
            for (var type in objectTypes){
                if (objectTypes.hasOwnProperty(type)){
                    var list = objectTypes[type];
                    for (var i=0; i<list.length; i++){
                        var field = list[i];
                        if (type === 'arr'){
                            var arrVal = val[field.fieldName];
                            if (arrVal !== undefined){
                                this.arr(field.fieldName, arrVal, field.fieldType, field.objectTypes);
                                this.value += ",";
                            }
                        } else if (type === 'obj'){
                            var objVal = val[field.fieldName];
                            if (objVal !== undefined){
                                this.obj(field.fieldName, objVal, field.objectTypes);
                                this.value += ",";
                            }
                        } else {
                            var typeVal = val[field];
                            if (typeVal !== undefined){
                                this[type](field, typeVal);
                                this.value += ",";
                            }
                        }
                    }
                }
            }
            if (this.value.slice(-1) === ',')
                this.value = this.value.slice(0, -1);
            this.value += '} ';
            return this;
        }
        //2d array not supported yet
        QueryParam.prototype.arr = function(paramName, val, fieldType, objectTypes){    //fieldType: {str, val, enum, obj}, objectTypes: {str:[fieldName], val:[fieldName], enum:[fieldName], obj:[{objectTypes}}
            if (paramName){
                paramName = paramName + ":";
            } else {
                paramName = "";
            }

            if (!val){
                this.value += paramName + '[] ';
                return this;
            }

            this.value += paramName + '[ ';
            for (var i=0; i<val.length; i++){
                if (i != 0)
                    this.value += ',';
                this[fieldType](null, val[i], objectTypes);
            }
            this.value +='] ';
            return this;
        }
        QueryParam.prototype.get = function(){
            return "(" + this.value.trim() + ")";
        }
    // graphQL

    // interceptor
        //should call bind to this function
        function apolloSuccessInterceptor(key, res){
            if (res.errors || !res.data){
                apolloErrorInterceptor(res);
            } else {
                res = res.data;
                return key && res.hasOwnProperty(key) ? res[key] : res;
            }
        }
        function apolloErrorInterceptor(res) {
            console.error("api call fail", res);
            throw res;
        }
    // interceptor

        return {

            getRequestMSCodeURL: function(state, codeChallenge, noPrompt){
                return MS_ENDPOINT + "oauth2/v2.0/authorize?client_id=" + CLIENT_ID +
                    "&response_type=code&response_mode=query&prompt=" + (noPrompt ? "none" : "select_account") +
                    "&redirect_uri=" + encodeURIComponent(thisDomain) +
                    "&code_challenge=" + codeChallenge +
                    "&code_challenge_method=S256" +
                    "&scope=openid%20email%20profile%20offline_access" +
                    "&state=" + state;
            },

            getTokenByMSCode: function(code, success, error){
                var request = new XMLHttpRequest();
                request.open('POST', MS_ENDPOINT + "oauth2/v2.0/token", true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function() {
                    try {
                        if (request.status == 200) {
                            success(JSON.parse(request.response));
                        } else {
                            error(JSON.parse(request.response));
                        }
                    } catch (e) {
                        error({ error: e });
                    }
                }
                request.onerror = function() {
                    error({ error: 'unexpected error' });
                }
                var codeVerifier = Service.storageGet("codeVerifier");
                var body = 'grant_type=authorization_code&code=' + code + '&client_id=' + CLIENT_ID + '&redirect_uri=' + thisDomain + '&code_verifier=' + codeVerifier + '&scope=openid email profile offline_access';
                request.send(body);
            },

            refreshByRefreshToken: function(refreshToken, success, error){
                var request = new XMLHttpRequest();
                request.open('POST', MS_ENDPOINT + "oauth2/v2.0/token", true);
                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                request.onload = function() {
                    try {
                        if (request.status == 200) {
                            success(JSON.parse(request.response));
                        } else {
                            error(JSON.parse(request.response));
                        }
                    } catch (e) {
                        error({ error: e });
                    }
                }
                request.onerror = function() {
                    error({ error: 'unexpected error' });
                }
                var body = 'grant_type=refresh_token&refresh_token=' + refreshToken + '&client_id=' + CLIENT_ID + '&scope=openid email profile offline_access';
                request.send(body);
            },
            refreshCognitoToken: function(refreshToken) {
              return fetch(URL + "auth/token", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
                credentials: "include",
                body: `refresh_token=${encodeURIComponent(refreshToken)}`
              }).then(function(response) {
                if (response.ok) {
                  return response.json();
                } else {
                  console.log("fetch not ok");
                  throw response;
                }
              });
            },

            loginWithOAuth: function(idToken){
                return $http.post(URL + "auth/oauth/microsoft", {
                    token: idToken
                });
            },

            initApolloClient: initApolloClient,

            logSignIn: function(){
                var queryName = 'recordSignInEvent';
                // var selector = new QueryParam().str("id", id);
                // var str = queryName + selector.get();
                var str = queryName;
                return toApolloMutation(str).then(apolloSuccessInterceptor.bind(null, queryName), apolloErrorInterceptor);
            }

            // login: function(email, pwd){
            //     return $http.post(URL + 'signin', {
            //         email: email,
            //         password: pwd,
            //     });
            // },

            // selfRegister: function(email, fName, lName){
            //     return $http.post(URL + 'createProfile', {
            //         email: email,
            //         firstName: fName,
            //         lastName: lName
            //     });
            // },

            // getCaptcha: function(){
            //     return $http.get(URL + 'captcha');
            // },

            // valiateCaptcha: function(code){
            //     return $http.post(URL + 'validate', {
            //         text: code
            //     });
            // },

            // sendForgotPwdEmail: function(email, site){
            //     return $http.post(URL + 'recover', {
            //         email: email,
            //         site: site
            //     });
            // },

            // resetPwd: function(pwd, token){
            //     return $http.post(URL + 'recover/password', {
            //         password: pwd,
            //         token: token
            //     });
            // },

            // activateAccount: function(token, fName, lName, pwd){
            //     return $http.post(URL + 'activation', {
            //         token: token,
            //         firstName: fName,
            //         lastName: lName,
            //         password: pwd
            //     });
            // },

            // validateActivationToken: function(token){
            //     return $http.post(URL + 'activation/validation', {
            //         token: token
            //     });
            // },

            // validateResetPwdToken: function(token){
            //     return $http.post(URL + 'recover/validation', {
            //         token: token
            //     });
            // }

        }
    }

})();
