(function() {
    'use strict';

    angular.module('EntrakV5').service('Service', ['$rootScope', '$http', 'KEY', 'LANG', 'URL', 'COOKIE_PREFIX', service]);

    function service($rootScope, $http, KEY, LANG, URL, COOKIE_PREFIX) {
        console.log('service');

        function storageSave(key, value){
            try {
                if (typeof(Storage)){
                    localStorage.setItem(key, value);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageDelete(key){
            try {
                if (typeof(Storage)){
                    localStorage.removeItem(key);
                    return true;
                }
            } catch(e) {
                console.error(e);
            }
            return false;
        }

        function storageGet(key){
            try {
                if (typeof(Storage))
                    return localStorage[key];
            } catch(e) {
                console.error(e);
            }
        }

        function toSupportedLangCode(langCode){
            if (langCode)
                langCode = langCode.toLowerCase();
            if (langCode === KEY.en || langCode === KEY.cn || langCode === KEY.zh){
                return langCode;
            }
            return KEY.en;
        }

        function updateLangCode(langCode, dontSave){
            langCode = toSupportedLangCode(langCode);
            if (langCode !== $rootScope.langCode){
                if (langCode === KEY.zh){
                    var kendoLangCode = "zh-TW";
                } else if (langCode === KEY.cn){
                    var kendoLangCode = "zh-CN";
                } else {
                    var kendoLangCode = "en-US";
                }

                if (!dontSave)
                    storageSave("appLanguage", langCode);
                $rootScope.langCode = langCode;
                kendo.culture(kendoLangCode);
            }

            return langCode;
        }


        return {

            getCookie: function(key) {
              let name = COOKIE_PREFIX + key + "=";
              let cookies = decodeURIComponent(document.cookie).split(';');
              for(let i=0; i<cookies.length; i++) {
                let c = cookies[i].trimStart();
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            },

            setCookie: function(key, value, expiryDays) {
              const d = new Date();

              d.setTime(d.getTime() + ((expiryDays || 30) * 24 * 3600 * 1000));
              let expires = "expires=" + d.toUTCString();
              document.cookie = COOKIE_PREFIX + key + "=" + value + ";" + expires + ";domain=.en-trak.com;path=/";
            },

            deleteCookie: function(key) {
              document.cookie = COOKIE_PREFIX + key + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=.en-trak.com;path=/";
            },

            generalCodeVerifierAndChallenge: function(){
                var verifier = "";
                var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
                for (var i=0; i<80; i++) {
                    verifier += possible.charAt(Math.floor(Math.random() * possible.length));
                }
                var challenge = CryptoJS.SHA256(verifier).toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');

                return {
                    verifier: verifier,
                    challenge: challenge
                }
            },

            arrayToMap: function(arr, key){
                if (!key)
                    key = 'id';
                var map = {};
                if (arr){
                    for (var i=0; i<arr.length; i++){
                        map[arr[i][key]] = arr[i];
                    }
                }

                return map;
            },

            //by id
            replaceArrItem: function(arr, items, addIfNotFound){
                if (!Array.isArray(items))
                    items = [items];

                for (var i=0; i<items.length; i++){
                    var j=0;
                    for (; j<arr.length; j++){
                        if (arr[j].id == items[i].id){
                            arr.splice(j, 1, items[i]);
                            break;
                        }
                    }
                    if (addIfNotFound && j == arr.length)
                        arr.push(items[i]);
                }
            },

            //by id
            deleteArrItem: function(arr, itemOrId){
                if (itemOrId != null && typeof itemOrId === 'object')
                    itemOrId = itemOrId.id;

                for (var j=0; j<arr.length; j++){
                    if (arr[j].id == itemOrId){
                        return arr.splice(j, 1)[0];
                        break;
                    }
                }
            },

            //by id
            getArrItem: function(arr, val, keyName){
                if (!keyName)
                    keyName = "id";
                for (var j=0; j<arr.length; j++){
                    if (arr[j][keyName] == val)
                        return arr[j];
                }
                return null;
            },

            //default field: "name"
            getSorter: function(field){
                if (!field)
                    field = "name";
                return function(a, b){
                    if (a[field] > b[field]){
                        return 1;
                    } else if (a[field] < b[field]){
                        return -1;
                    } else {
                        return 0;
                    }
                }
            },

            isEmail: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },

            // isValidPwd: function(pwd, isAdv){
            //     if (isAdv){
            //         if (pwd && pwd.length >= 10){
            //             var grpCount = 0;
            //             if (/[0-9]+/.test(pwd))
            //                 grpCount++;
            //             if (/[a-z]+/.test(pwd))
            //                 grpCount++;
            //             if (/[A-Z]+/.test(pwd))
            //                 grpCount++;
            //             //https://docs.oracle.com/cd/E11223_01/doc.910/e11197/app_special_char.htm#MCMAD416
            //             if (/[`~@%+\\/',!#$^?:.(){}\[\]\-_]+/.test(pwd))
            //                 grpCount++;
            //             return grpCount >= 2;
            //         }
            //     } else {
            //         if (pwd && pwd.length >= 8 && pwd.length <= 16)
            //             return (/[0-9]+/.test(pwd) && /[a-zA-Z]+/.test(pwd) && /^[0-9a-zA-Z]+$/.test(pwd));
            //     }
            //     return false;
            // },

            dateFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = new Date(date);
                
                if (fmt === "long") {
                    fmt = "L";    //dddd, d MMMM yyyy
                } else if (!fmt){
                    fmt = "L";    //dddd, d MMMM yyyy
                }
                return kendo.toString(date, fmt);
            },
            timeFmt: function(date, fmt){
                if (!date)
                    return '';

                if (typeof date === 'number' || typeof date === 'string')
                    date = new Date(date);
                
                if (fmt === "long") {
                    fmt = "T";    //h:mmtt
                } else if (fmt === "short"){
                    fmt = "t";    //htt
                } else if (!fmt){
                    fmt = "T";    //h:mmtt
                }
                return kendo.toString(date, fmt);
            },
            datetimeFmt: function(date, fmt){
                if (!date)
                    return '';
                
                if (typeof date === 'number' || typeof date === 'string')
                    date = new Date(date);
                
                if (fmt === "long") {
                    fmt = "F";    //h:mmtt, d MMM yyyy
                } else if (!fmt){
                    fmt = "F";    //h:mmtt, d MMM yyyy
                }
                return kendo.toString(date, fmt);
            },

            numFmt: function(val, decimal){
                if (!decimal)
                    decimal = 0;
                val = parseFloat(val);
                return isNaN(val) ? "" : val.toFixed(decimal);
            },

            formPost: function (endpoint, dataObj) {
                const arr = Object.keys(dataObj || {});
                return fetch(URL + endpoint, {
                  method: "POST",
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
                  credentials: "include",
                  body: arr.map(k => `${k}=${encodeURIComponent(dataObj[k])}`).join("&")
                });
            },
            parseJson: function(response) {
                if (response.ok) {
                  return response.json();
                } else {
                  console.log('fetch not ok');
                  throw response;
                }
            },
            parseBlob: function(response) {
                if (response.ok) {
                  return response.blob();
                } else {
                  console.log('fetch not ok');
                  throw response;
                }
            },

            translate: function(key, params){
                if (!key)
                    return '';
                
                var arr = key.trim().split(".");
                var node = LANG[$rootScope.langCode];
                for (var i=0; i<arr.length; i++){
                    node = node[arr[i]];
                    if (node == null)
                        return '';
                }

                if (params){
                    for (var key in params){
                        if (params.hasOwnProperty(key)){
                            node = node.replace("${" + key + "}", params[key]);
                        }
                    }
                }
                return node;
            },

            initLangCode: function(){
                var lang = storageGet("appLanguage");
                if (lang){
                    updateLangCode(lang, true);
                } else {
                    if (window.navigator.language){
                        var langCode = window.navigator.language.split("-")[0];
                    } else if (window.navigator.userLanguage){
                        var langCode = window.navigator.userLanguage.split("-")[0];
                    } else if (window.navigator.browserLanguage){
                        var langCode = window.navigator.browserLanguage.split("-")[0];
                    } else if (window.navigator.systemLanguage){
                        var langCode = window.navigator.systemLanguage.split("-")[0];
                    }
                    updateLangCode(langCode);
                }
            },
            setLangCode: function(langCode){
                updateLangCode(langCode);
            },
            toSupportedLangCode: toSupportedLangCode,

            storageSave: storageSave,
            storageGet: storageGet,
            storageDelete: storageDelete,

        }
    }

})();
