(function() {
    'use strict';

    angular.module('EntrakV5').filter('fileName', function(){
        return function(url){
            return url.substring(url.lastIndexOf('/') + 1);
        }
    }).filter('dateFmt', ['Service', function(Service){
        return Service.dateFmt;
    }]).filter('timeFmt', ['Service', function(Service){
        return Service.timeFmt;
    }]).filter('datetimeFmt', ['Service', function(Service){
        return Service.datetimeFmt;
    }]).filter('numFmt', ['Service', function(Service){
        return Service.numFmt;
    }]);

})();
