(function() {
    'use strict';

    angular.module('EntrakV5').config(routerConfig);

    function routerConfig($stateProvider, $urlRouterProvider) {
        console.log('config');
        
        $stateProvider.state('login', {
            url: '/login/:landlord?lang',
            template: require('./login/login.html'),
            controller: 'loginController'
        }).state('registration', {
            url: '/registration',
            template: require('./login/login.html'),
            controller: 'registrationController'
        }).state('resetPwd', {
            url: '/resetPwd/:email/:code',
            template: require('./login/login.html'),
            controller: 'resetPwdController'
        }).state('activation', {
            url: '/activation/:email/:token',
            template: require('./login/login.html'),
            controller: 'activationController'
        }).state('codeHandler?error?error_description', {//for ms signIn redirect
            url: '/codeHandler',
            template: require('./login/loading.html'),
            controller: 'codeHandlerController'
        }).state('refresh', {//refresh token
            url: '/refresh/:landlord?cb',
            template: require('./login/loading.html'),
            controller: 'refreshController'
        });

        $urlRouterProvider.otherwise('/codeHandler');
        // $urlRouterProvider.otherwise('/login/');
    }

    angular.module('EntrakV5').config(['$httpProvider', function($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
    }]);

})();
