(function() {
    angular.module('EntrakV5').controller('appController', appController);

    function appController($scope, $rootScope, $transitions, Service) {
        console.log('appController');

    //global value
        // $rootScope.previousState
        // $rootScope.langCode
        $rootScope.currentState = '';
        $rootScope.loadingPage = false;
    //global value

    //global constant
    //global constant

    //global function
    //global function

        $scope.langDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("lang.en"),
                short: Service.translate("lang.enShort"),
                value: 'en'
            }, {
                text: Service.translate("lang.zh"),
                short: Service.translate("lang.zhShort"),
                value: 'zh'
            }, {
                text: Service.translate("lang.cn"),
                short: Service.translate("lang.cnShort"),
                value: 'cn'
            }],
            dataTextField: "text",
            dataValueField: "value",
            valueTemplate: '#=data.short#',
            template: '#=data.text#',
            select: function(e) {
                Service.setLangCode(e.dataItem.value);
                window.location.reload();
            }
        }

    //transition
        $transitions.onStart({}, function(trans) {
            console.log('start: ' + trans.from().name + " -> " + trans.to().name);
            $rootScope.loadingPage = false;
            //return false to cancel transition
        });

		$transitions.onSuccess({}, function(trans) {
			console.log('success: ' + trans.from().name + " -> " + trans.to().name);
            $rootScope.previousState = $rootScope.currentState;
			$rootScope.currentState = trans.to().name;
		});
    //transition

        $scope.$on('$destroy', function() {
            console.log("appController destroy");
        });
    }
})();
