(function() {
    'use strict';

    angular.module('EntrakV5').constant('KEY', {
        en: "en",
        zh: "zh",
        cn: "cn",
        scrollBarWidth: (function(){
            var outer = document.createElement("div");
            outer.style.visibility = "hidden";
            outer.style.overflow = "scroll";
            outer.style.width = "100px";
            document.body.appendChild(outer);

            var inner = document.createElement("div");
            inner.style.width = "100%";
            outer.appendChild(inner);

            var width = outer.offsetWidth - inner.offsetWidth;
            outer.parentNode.removeChild(outer);

            return width;
        })(),
        pwdPolicy: {
            general: "GENERAL",
            advanced: "ADVANCED"
        },
    });
})();
